/* utils */
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li, ol, ol li {
  list-style: none;
}

img {
  display: block;
}

html,
body {
  position: relative;
}

img, svg {
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans", serif;
}

p {
  font-size: 16px;
}
p span {
  background-color: #F78F1E;
}
p a {
  color: #3180D0;
  text-decoration: underline !important;
  font-weight: 400;
  transition: 0.4s ease all;
}
@media (hover: hover) {
  p a:hover {
    cursor: pointer;
    color: #F78F1E;
  }
}
p a:focus-visible {
  color: #F78F1E;
}

h1 {
  font-size: 64px;
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 1024px) {
  h2 {
    font-size: 24px;
  }
}
h2 span {
  background-color: #F78F1E;
  color: white;
  font-size: 36px;
}
@media screen and (max-width: 1024px) {
  h2 span {
    font-size: 26px;
  }
}

.wrapper-color {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.none {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

html, body {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  position: relative;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
}

footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

/* Components */
footer .container {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 96px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  footer .container {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 1024px) {
  footer .container {
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 55px;
  }
}
@media screen and (max-width: 768px) {
  footer .container {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
footer .container .info {
  display: flex;
}
@media screen and (max-width: 1024px) {
  footer .container .info {
    order: 2;
  }
}
@media screen and (max-width: 580px) {
  footer .container .info {
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }
}
footer .container .info a {
  transition: 0.4s ease all;
}
@media (hover: hover) {
  footer .container .info a:hover {
    cursor: pointer;
    scale: 1.1;
  }
}
footer .container .info a:focus-visible {
  scale: 1.1;
}
footer .container .info a > img {
  display: block;
  object-fit: scale-down;
  flex-shrink: 0;
  max-width: 144px;
  max-height: 50px;
}
footer .container .info .usefull {
  margin-left: 36px;
  display: flex;
  align-items: center;
  gap: 16px;
}
@media screen and (max-width: 580px) {
  footer .container .info .usefull {
    flex-direction: column;
    margin-left: 0;
    gap: 0;
  }
}
footer .container .info .usefull a {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: black;
}
@media screen and (max-width: 380px) {
  footer .container .info .usefull a {
    display: none;
  }
}
@media (hover: hover) {
  footer .container .info .usefull a:hover {
    cursor: pointer;
    color: #0d6bca;
    text-decoration: underline;
  }
}
footer .container .info .usefull a:focus-visible {
  color: #0d6bca;
  text-decoration: underline;
}
@media screen and (max-width: 580px) {
  footer .container .info .usefull a:last-child {
    margin-top: -3px;
  }
}
@media screen and (max-width: 580px) {
  footer .container .info .usefull p {
    display: none;
  }
}
footer .container .info .usefull .mobile {
  display: none;
}
@media screen and (max-width: 380px) {
  footer .container .info .usefull .mobile {
    display: block;
  }
}
footer .container .info .usefull .mobile a {
  display: block;
}
footer .container .info .usefull .mobile a p {
  display: block;
  text-decoration: underline;
  text-align: center;
}
footer .usefull-links {
  display: flex;
  height: fit-content;
  gap: 36px;
  align-items: center;
}
@media screen and (max-width: 580px) {
  footer .usefull-links {
    flex-direction: column;
    width: 100%;
    gap: 11px;
  }
}
footer .usefull-links .socials {
  display: flex;
  gap: 24px;
  align-items: center;
}
footer .usefull-links .socials a {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease all;
}
footer .usefull-links .socials a .icon {
  width: 29px;
  height: 29px;
  flex-shrink: 0;
  transition: 0.4s ease all;
}
@media (hover: hover) {
  footer .usefull-links .socials a:hover {
    cursor: pointer;
  }
  footer .usefull-links .socials a:hover .icon {
    scale: 1.2;
  }
}
footer .usefull-links .socials a:focus-visible .icon {
  scale: 1.2;
}
footer .usefull-links > a {
  padding: 4px 31px 3px 31px;
  background-color: #004F9E;
  border-radius: 5px;
  background: #004F9E;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  transition: 0.4s ease all;
}
@media screen and (max-width: 580px) {
  footer .usefull-links > a {
    padding: 5px;
    width: 100%;
    text-align: center;
  }
}
@media (hover: hover) {
  footer .usefull-links > a:hover {
    cursor: pointer;
    background-color: #0d6bca;
  }
}
footer .usefull-links > a:focus-visible {
  background-color: #0d6bca;
}

header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
header .container {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 28px;
  padding-top: 56px;
  transition: 0.6s ease all;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  header .container {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
header .container.scrolled {
  padding-bottom: 12px;
  padding-top: 20px;
}
@media screen and (max-width: 1260px) {
  header .container {
    flex-direction: column;
    justify-content: initial;
  }
  header .container.scrolled {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 840px) {
  header .container {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 33px;
  }
  header .container.scrolled {
    padding-top: 12px;
    padding-bottom: 6px;
  }
}
header .container .logos {
  display: flex;
  gap: 42px;
  align-items: center;
}
@media screen and (max-width: 1260px) {
  header .container .logos {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 840px) {
  header .container .logos {
    margin: initial;
    gap: 12px;
  }
}
header .container .logos a {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  transition: 0.4s ease all;
  will-change: scale;
}
@media (hover: hover) {
  header .container .logos a:hover {
    cursor: pointer;
    scale: 1.1;
  }
}
header .container .logos a:focus-visible {
  scale: 1.1;
}
header .container .logos a:first-child img {
  width: 142.718px;
  height: 60px;
  flex-shrink: 0;
}
@media screen and (max-width: 840px) {
  header .container .logos a:first-child img {
    width: 109px;
    height: 46px;
    flex-shrink: 0;
  }
}
header .container .logos a:nth-child(2) img {
  width: 170px;
  height: 60px;
  flex-shrink: 0;
  object-fit: cover;
}
@media screen and (max-width: 840px) {
  header .container .logos a:nth-child(2) img {
    height: 49px;
    max-width: 136px;
  }
}
header .container .links {
  display: flex;
  gap: 36px;
  align-items: center;
  margin-top: 12px;
}
@media screen and (max-width: 1260px) {
  header .container .links {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 840px) {
  header .container .links {
    display: none;
  }
}
header .container .links a {
  color: #004F9E;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  transition: 0.6s ease all;
}
header .container .links a.active:after {
  width: calc(100% - 3px);
}
header .container .links a:after {
  content: "";
  position: absolute;
  top: calc(100% + 6px);
  left: 1px;
  height: 5px;
  background: #F78F1E;
  width: 0;
  transition: 0.6s ease all;
}
@media (hover: hover) {
  header .container .links a:hover {
    cursor: pointer;
    color: #F78F1E;
  }
  header .container .links a:hover:after {
    width: calc(100% - 3px);
  }
}
header .container .links a:focus-visible {
  color: #F78F1E;
}
header .container .links a:focus-visible:after {
  width: calc(100% - 3px);
}
header .container .mobile-menu-container {
  display: none;
}
@media screen and (max-width: 840px) {
  header .container .mobile-menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-header-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  z-index: 1000;
  background-color: rgba(11, 25, 39, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 80px 10px;
  overflow: auto;
}
.modal-header-menu .content {
  position: relative;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
}
.modal-header-menu .content .close {
  position: absolute;
  top: -40px;
  right: -10px;
  padding: 10px;
  transition: 0.4s ease all;
}
@media (hover: hover) {
  .modal-header-menu .content .close:hover {
    cursor: pointer;
    scale: 1.1;
  }
}
.modal-header-menu .content .close:focus-visible {
  scale: 1.1;
}
.modal-header-menu .content .close .line {
  width: 34px;
  height: 3px;
  border-radius: 30px;
  background: #F78F1E;
  flex-shrink: 0;
}
.modal-header-menu .content .close .line.line1 {
  rotate: 45deg;
  transform-origin: center center;
}
.modal-header-menu .content .close .line.line2 {
  position: relative;
  top: -3px;
  transform: translateY(1px);
  rotate: -45deg;
  transform-origin: center center;
}
.modal-header-menu .content a {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  transition: 0.6s ease all;
}
.modal-header-menu .content a.active:after {
  width: calc(100% - 3px);
}
.modal-header-menu .content a:after {
  content: "";
  position: absolute;
  top: calc(100% + 6px);
  left: 1px;
  height: 5px;
  background: #F78F1E;
  width: 0;
  transition: 0.6s ease all;
}
@media (hover: hover) {
  .modal-header-menu .content a:hover {
    cursor: pointer;
    color: #F78F1E;
  }
  .modal-header-menu .content a:hover:after {
    width: calc(100% - 3px);
  }
}
.modal-header-menu .content a:focus-visible {
  color: #F78F1E;
}
.modal-header-menu .content a:focus-visible:after {
  width: calc(100% - 3px);
}

.index-main-banner {
  margin-inline: auto;
  max-width: 2200px;
  width: 100%;
  display: flex;
  overflow: hidden;
  margin-top: 28px;
}
@media screen and (max-width: 768px) {
  .index-main-banner {
    margin-top: 12px;
  }
}
.index-main-banner .left-container {
  max-width: 820px;
  width: 37.63%;
  flex-shrink: 0;
}
.index-main-banner .container {
  width: 100%;
  min-width: 828px;
  padding: 0 93px 0 96px;
  min-height: 624px;
  display: flex;
  flex-direction: column;
  gap: 44px;
}
@media screen and (max-width: 1240px) {
  .index-main-banner .container {
    min-width: 0;
    padding: 0 40px;
  }
}
@media screen and (max-width: 1024px) {
  .index-main-banner .container {
    padding: 0 10px;
    gap: 25px;
    min-height: 0;
  }
}
.index-main-banner .container h1 {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.index-main-banner .container p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.index-main-banner .right-container {
  width: 24.72%;
  max-width: 544px;
  flex-shrink: 0;
}
@media screen and (max-width: 1400px) {
  .index-main-banner .left-container {
    width: 15%;
  }
}
@media screen and (max-width: 1024px) {
  .index-main-banner .left-container {
    display: none;
  }
  .index-main-banner .right-container {
    display: none;
  }
  .index-main-banner .container {
    min-width: 0;
  }
}
.index-main-banner .image-container {
  position: relative;
  overflow: hidden;
}
.index-main-banner .image-container img {
  position: absolute;
  border-radius: 8px;
  display: block;
  object-fit: cover;
}
.index-main-banner .left-container img:nth-child(1) {
  max-width: 260px;
  height: 240px;
  top: 0;
  right: 0;
}
.index-main-banner .left-container img:nth-child(2) {
  right: 283px;
  top: 0;
  width: 260px;
  height: 360px;
}
.index-main-banner .left-container img:nth-child(3) {
  right: 566px;
  top: 0;
  width: 252px;
  height: 240px;
}
.index-main-banner .left-container img:nth-child(4) {
  right: 0;
  top: 263px;
  width: 260px;
  height: 360px;
}
.index-main-banner .left-container img:nth-child(5) {
  right: 283px;
  top: 383px;
  width: 260px;
  height: 240px;
}
.index-main-banner .left-container img:nth-child(6) {
  right: 566px;
  top: 263px;
  width: 252px;
  height: 360px;
}
.index-main-banner .right-container img:nth-child(1) {
  left: 0;
  top: 0;
  width: 260px;
  height: 360px;
}
.index-main-banner .right-container img:nth-child(2) {
  left: 283px;
  top: 0;
  width: 260px;
  height: 240px;
}
.index-main-banner .right-container img:nth-child(3) {
  left: 0;
  top: 383px;
  width: 260px;
  height: 240px;
}
.index-main-banner .right-container img:nth-child(4) {
  left: 283px;
  top: 263px;
  width: 260px;
  height: 360px;
}

.contest {
  margin-top: 158px;
  max-width: 1920px;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: url("../../assets/backgrounds/contest.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1921px) {
  .contest {
    border-radius: 8px;
  }
}
.contest .absolute-bg {
  position: absolute;
  top: -600px;
  left: 0;
  width: 100%;
  height: calc(100% + 700px);
  background: url("../../assets/backgrounds/bg1.svg");
  background-repeat: repeat;
  z-index: 0;
}
@media screen and (max-width: 1024px) {
  .contest {
    margin-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .contest {
    margin-top: 18px;
  }
}
.contest .content {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding-top: 78px;
  padding-bottom: 174px;
  gap: 24px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .contest .content {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 1024px) {
  .contest .content {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .contest .content {
    padding-top: 30px;
    padding-bottom: 34px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
.contest .content .text {
  grid-column: 1/6;
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.contest .content .text h1 {
  color: white;
  max-width: 440px;
  font-weight: 700;
}
.contest .content .text p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
  max-width: 658px;
}
.contest .content .video-block {
  grid-column: 6/10;
  margin-top: 25px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contest .content .video-block iframe {
  width: 100%;
  aspect-ratio: 560/315;
  border-radius: 8px;
}

.terms-of-participation {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 106px;
  margin-bottom: 123px;
  display: flex;
  flex-direction: column;
  gap: 46px;
}
@media screen and (max-width: 1024px) {
  .terms-of-participation {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 768px) {
  .terms-of-participation {
    margin-top: 30px;
    margin-bottom: 36px;
    gap: 32px;
  }
}
.terms-of-participation .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 24px;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
.terms-of-participation .grid .terms-grid-item {
  display: flex;
  gap: 24px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item {
    align-items: start;
    gap: 11px;
  }
}
.terms-of-participation .grid .terms-grid-item .border {
  width: 72px;
  height: 72px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #F78F1E;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .border {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
  }
}
.terms-of-participation .grid .terms-grid-item .border .icon {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .border .icon {
    width: 24px;
    height: 24px;
  }
}
.terms-of-participation .grid .terms-grid-item .text-block {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}
.terms-of-participation .grid .terms-grid-item .text-block .desk {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .text-block .desk {
    display: none;
  }
}
.terms-of-participation .grid .terms-grid-item .text-block .mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .text-block .mobile {
    display: flex;
    flex-direction: column;
  }
}
.terms-of-participation .grid .terms-grid-item .text-block p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .text-block p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .text-block.small p {
    font-size: 14px;
  }
}
.terms-of-participation .grid .terms-grid-item .text-block ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item .text-block ul li {
    font-size: 14px;
  }
}
.terms-of-participation .grid .terms-grid-item:nth-child(2) p {
  max-width: 351px;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item:nth-child(2) p {
    max-width: none;
  }
}
.terms-of-participation .grid .terms-grid-item:nth-child(3) p {
  max-width: 250px;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item:nth-child(3) p {
    max-width: none;
  }
}
.terms-of-participation .grid .terms-grid-item:nth-child(4) p {
  max-width: 281px;
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item:nth-child(4) p {
    max-width: none;
  }
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item:nth-child(5) p {
    max-width: none;
  }
}
@media screen and (max-width: 768px) {
  .terms-of-participation .grid .terms-grid-item:nth-child(6) p {
    max-width: none;
  }
}

.stages {
  position: relative;
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  background: url("../../assets/backgrounds/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-inline: auto;
}
@media screen and (min-width: 1921px) {
  .stages {
    border-radius: 8px;
  }
}
.stages .absolut-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/backgrounds/bg1.svg");
  background-repeat: repeat;
  z-index: 0;
}
.stages .content {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 98px;
  margin-bottom: 120px;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .stages .content {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 1024px) {
  .stages .content {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .stages .content {
    margin-top: 25px;
    margin-bottom: 51px;
  }
}
.stages .content h1 {
  color: white;
}
.stages .content .stages-container {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container {
    gap: 27px;
  }
}
.stages .content .stages-container .stages-item {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 24px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .stages-item {
    display: flex;
    flex-direction: column;
    gap: 38px;
    padding-left: 30px;
    padding-right: 11px;
  }
}
.stages .content .stages-container .stages-item .times {
  grid-column: 1/5;
  display: flex;
  flex-direction: column;
  align-items: end;
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .stages-item .times {
    align-items: start;
  }
}
.stages .content .stages-container .stages-item .times h2 {
  color: white;
  text-align: right;
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .stages-item .times h2 {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .stages-item:before {
    position: absolute;
    left: -2px;
    content: "";
    top: 5px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 100%;
  }
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .stages-item:after {
    position: absolute;
    left: 9px;
    content: "";
    top: 5px;
    width: 2px;
    height: calc(100% + 27px + 5px);
    background-color: #fff;
  }
}
.stages .content .stages-container .stages-item:last-child:after {
  display: none;
}
.stages .content .stages-container .stages-item:last-child .line:after {
  display: none;
}
.stages .content .stages-container .info-block-container {
  grid-column: 5/15;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 24px;
}
@media screen and (max-width: 1024px) {
  .stages .content .stages-container .info-block-container {
    grid-template-columns: repeat(9, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .info-block-container {
    display: flex;
    flex-direction: column;
  }
}
.stages .content .stages-container .info-block-container .line {
  grid-column: 1/3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .stages .content .stages-container .info-block-container .line {
    grid-column: 1/2;
  }
}
@media screen and (max-width: 768px) {
  .stages .content .stages-container .info-block-container .line {
    display: none;
  }
}
.stages .content .stages-container .info-block-container .line:before {
  position: absolute;
  top: -12px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  content: "";
}
@media screen and (max-width: 1024px) {
  .stages .content .stages-container .info-block-container .line:before {
    width: 24px;
    height: 24px;
  }
}
.stages .content .stages-container .info-block-container .line:after {
  content: "";
  position: absolute;
  height: calc(100% + 64px);
  width: 2px;
  background: #FFF;
}
.stage-block {
  grid-column: 3/10;
  padding: 24px 0 31px 0;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .stage-block {
    grid-column: 3/11;
  }
}
@media screen and (max-width: 1024px) {
  .stage-block {
    grid-column: 2/10;
  }
}
@media screen and (max-width: 768px) {
  .stage-block {
    padding: 20px 0 24px 0;
    display: none;
  }
}
.stage-block:after {
  content: "";
  position: absolute;
  left: -24px;
  top: 0;
  width: 40px;
  height: 22px;
  background: url("../../assets/icons/polygon-horiz.svg");
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .stage-block:after {
    top: -14px;
    left: 0;
    background: url("../../assets/icons/polygon-vertical.svg");
    background-repeat: no-repeat;
    width: 19.5px;
    height: 40px;
  }
}
.stage-block.mobile {
  display: none;
}
.stage-block .part {
  display: flex;
  flex-direction: column;
  padding: 0 45px 0 37px;
}
@media screen and (max-width: 768px) {
  .stage-block .part {
    padding: 0 7px 0 20px;
  }
}
.stage-block .part p {
  margin-top: 19px;
}
@media screen and (max-width: 768px) {
  .stage-block .part p {
    margin-top: 13px;
  }
}
.stage-block .part h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .stage-block .part h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .stage-block .part h3.smaller {
    font-size: 18px;
  }
}
.stage-block .horizontal-line {
  width: 100%;
  height: 1px;
  background: #CECECE;
  margin-top: 36px;
  margin-bottom: 24px;
}
@media screen and (max-width: 768px) {
  .stage-block .horizontal-line {
    margin-top: 25px;
  }
}
.stage-block-1, .stage-block-4 {
  display: flex;
  flex-direction: column;
}
.stage-block-1 > a,
.stage-block-1 .part > a, .stage-block-4 > a,
.stage-block-4 .part > a {
  margin-top: 31px;
  padding: 20px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background-color: #004F9E;
  width: fit-content;
  transition: 0.4s ease all;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (hover: hover) {
  .stage-block-1 > a:hover,
  .stage-block-1 .part > a:hover, .stage-block-4 > a:hover,
  .stage-block-4 .part > a:hover {
    cursor: pointer;
    background-color: #0d6bca;
  }
}
.stage-block-1 > a:focus-visible,
.stage-block-1 .part > a:focus-visible, .stage-block-4 > a:focus-visible,
.stage-block-4 .part > a:focus-visible {
  background-color: #0d6bca;
}
@media screen and (max-width: 768px) {
  .stage-block-1 > a,
  .stage-block-1 .part > a, .stage-block-4 > a,
  .stage-block-4 .part > a {
    margin-top: 24px;
    padding: 14px 10px;
    text-align: center;
    font-size: 12px;
  }
}
.stage-block-2 {
  display: flex;
  flex-direction: column;
}
.stage-block-2 .links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 31px;
}
@media screen and (max-width: 840px) {
  .stage-block-2 .links {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 768px) {
  .stage-block-2 .links {
    gap: 12px;
    margin-top: 19px;
  }
}
.stage-block-2 .part > a,
.stage-block-2 .part .part > a {
  margin-top: 31px;
  padding: 20px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background-color: #004F9E;
  width: fit-content;
  transition: 0.4s ease all;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (hover: hover) {
  .stage-block-2 .part > a:hover,
  .stage-block-2 .part .part > a:hover {
    cursor: pointer;
    background-color: #0d6bca;
  }
}
.stage-block-2 .part > a:focus-visible,
.stage-block-2 .part .part > a:focus-visible {
  background-color: #0d6bca;
}
@media screen and (max-width: 768px) {
  .stage-block-2 .part > a,
  .stage-block-2 .part .part > a {
    margin-top: 24px;
    padding: 14px 10px;
    text-align: center;
    font-size: 12px;
  }
}
.stage-block-3 {
  display: flex;
  flex-direction: column;
}
.stage-block-3 .part > a,
.stage-block-3 .part .part > a {
  margin-top: 31px;
  padding: 20px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background-color: #004F9E;
  width: fit-content;
  transition: 0.4s ease all;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (hover: hover) {
  .stage-block-3 .part > a:hover,
  .stage-block-3 .part .part > a:hover {
    cursor: pointer;
    background-color: #0d6bca;
  }
}
.stage-block-3 .part > a:focus-visible,
.stage-block-3 .part .part > a:focus-visible {
  background-color: #0d6bca;
}
@media screen and (max-width: 768px) {
  .stage-block-3 .part > a,
  .stage-block-3 .part .part > a {
    margin-top: 24px;
    padding: 14px 10px;
    text-align: center;
    font-size: 12px;
  }
}
.stage-block-3 .part .links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 31px;
  margin-bottom: 32px;
}
.stage-block-3 .part .links p {
  text-align: center;
}
@media screen and (max-width: 840px) {
  .stage-block-3 .part .links {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 768px) {
  .stage-block-3 .part .links {
    gap: 12px;
    margin-top: 19px;
    margin-bottom: 19px;
  }
  .stage-block-3 .part .links a p {
    width: fit-content;
  }
}
.stage-block-3 .part .animation-container {
  display: flex;
  flex-direction: column;
}
.stage-block-3 .part .animation-container .heading {
  display: flex;
  gap: 24px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .stage-block-3 .part .animation-container .heading {
    flex-direction: column;
  }
}
@media (hover: hover) {
  .stage-block-3 .part .animation-container .heading:hover {
    cursor: pointer;
  }
  .stage-block-3 .part .animation-container .heading:hover .box h6 {
    color: #0d6bca;
  }
}
.stage-block-3 .part .animation-container .heading.active svg {
  rotate: 180deg;
  color: #F78F1E;
}
.stage-block-3 .part .animation-container .heading .box h6 {
  color: #004F9E;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  text-decoration: underline;
  transition: 0.4s ease all;
}
.stage-block-3 .part .animation-container .heading svg {
  flex-shrink: 0;
  transition: 0.6s ease all;
  color: #d9d9d9;
}
.stage-block-3 .part .animation-container .body {
  display: flex;
  flex-direction: column;
}
.stage-block-3 .part .animation-container .body .body-content {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .stage-block-3 .part .animation-container .body .body-content {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
.stage-block-3 .part .animation-container .body .body-content .item p {
  margin: 0;
  line-height: 200%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.stage-block-1 .links, .stage-block-2 .links, .stage-block-3 .links, .stage-block-4 .links, .stage-block-5 .links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 31px;
}
@media screen and (max-width: 840px) {
  .stage-block-1 .links, .stage-block-2 .links, .stage-block-3 .links, .stage-block-4 .links, .stage-block-5 .links {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 768px) {
  .stage-block-1 .links, .stage-block-2 .links, .stage-block-3 .links, .stage-block-4 .links, .stage-block-5 .links {
    gap: 12px;
    margin-top: 19px;
  }
}

@media screen and (max-width: 768px) {
  .stage-block {
    display: none;
  }
}
.stage-block.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .stage-block.mobile {
    display: flex;
    flex-direction: column;
  }
}

.stage-link-with-icon {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px 20px 20px 18px;
  background-color: #004F9E;
  transition: 0.4s ease all;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .stage-link-with-icon {
    padding: 14px 10px;
    width: fit-content;
    align-items: center;
    justify-content: center;
  }
}
@media (hover: hover) {
  .stage-link-with-icon:hover {
    cursor: pointer;
    background-color: #0d6bca;
  }
}
.stage-link-with-icon:focus-visible {
  background-color: #0d6bca;
}
.stage-link-with-icon p {
  margin-top: 0px !important;
  font-size: 20px;
  font-style: normal;
  color: white;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .stage-link-with-icon p {
    font-size: 12px;
  }
}
.stage-link-with-icon img {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .stage-link-with-icon img {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }
}

.winners {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .winners {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 1024px) {
  .winners {
    margin-top: 60px;
  }
}
@media screen and (max-width: 76px) {
  .winners {
    margin-top: 30px;
  }
}
.winners__container {
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 24px;
}
@media screen and (max-width: 1200px) {
  .winners__container {
    grid-template-columns: repeat(10, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .winners__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .winners__container {
    grid-template-columns: 1fr;
    gap: 14px;
  }
}
.winners__container .winners-item {
  grid-column: span 4;
  padding: 24px 27px 23px 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  color: white;
  background-color: #D9D9D9;
  border-radius: 8px;
}
@media screen and (max-width: 1024px) {
  .winners__container .winners-item {
    grid-column: initial;
  }
}
@media screen and (max-width: 768px) {
  .winners__container .winners-item {
    padding: 23px 11px 17px 19px;
  }
}
.winners__container .winners-item img {
  position: absolute;
  top: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  object-fit: cover;
}
.winners__container .winners-item .heading {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 2;
}
.winners__container .winners-item .heading .count {
  width: 63px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .winners__container .winners-item .heading .count {
    width: 53px;
    height: 72px;
    flex-shrink: 0;
  }
}
.winners__container .winners-item .heading .count p {
  color: white;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .winners__container .winners-item .heading .count p {
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
}
.winners__container .winners-item .heading .count-1 {
  background: linear-gradient(24deg, rgba(203, 176, 22, 0.35) 3.48%, rgba(255, 174, 0, 0.7) 38.96%, rgba(203, 176, 22, 0.7) 92.19%), #D9D9D9;
}
.winners__container .winners-item .heading .count-2 {
  background: linear-gradient(201deg, rgba(0, 0, 0, 0) 11.66%, rgba(0, 0, 0, 0.4) 97.37%), #D9D9D9;
}
.winners__container .winners-item .heading .count-3 {
  background: linear-gradient(0deg, rgba(135, 91, 21, 0.7) -9.65%, rgba(124, 69, 6, 0.7) 52.72%, rgba(135, 91, 21, 0.7) 104.79%), #D9D9D9;
}
.winners__container .winners-item .heading h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.winners__container .winners-item p {
  position: relative;
  z-index: 2;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.winners__container .winners-item.big {
  grid-column: span 6;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .winners__container .winners-item.big {
    grid-column: initial;
  }
}
@media screen and (max-width: 1200px) {
  .winners__container .winners-item:nth-child(4) {
    grid-column: span 6;
  }
}
@media screen and (max-width: 1024px) {
  .winners__container .winners-item:nth-child(4) {
    grid-column: initial;
  }
}
@media screen and (max-width: 1200px) {
  .winners__container .winners-item:nth-child(5) {
    grid-column: span 6;
  }
}
@media screen and (max-width: 1024px) {
  .winners__container .winners-item:nth-child(5) {
    grid-column: initial;
  }
}

.history {
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  margin-top: 111px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .history {
    margin-top: 60px;
  }
}
@media screen and (max-width: 768px) {
  .history {
    margin-top: 36px;
  }
}
.history .container {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 24px;
}
@media screen and (max-width: 1024px) {
  .history .container {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 768px) {
  .history .container {
    grid-template-columns: 1fr;
  }
}
.history .container .content {
  grid-column: span 9;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .history .container .content {
    padding-bottom: 0;
  }
}
.history .container .content .text-block {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.history .container .content h2 {
  margin-top: 45px;
  max-width: 638px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .history .container .content h2 {
    font-size: 16px;
    margin-top: 22px;
  }
}
.history .container .content h2 + p {
  max-width: 638px;
}
.history .container .content h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .history .container .content h3 {
    margin-top: 13px;
    font-size: 16px;
  }
}
.history .container .image-block {
  grid-column: span 5;
  margin-top: 19px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .history .container .image-block {
    display: none;
  }
}
.history .container .image-block img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
  width: 760px;
  object-fit: cover;
}

.partners {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 125px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media screen and (max-width: 1024px) {
  .partners {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
@media screen and (max-width: 1024px) {
  .partners {
    margin-top: 70px;
    gap: 48px;
  }
}
@media screen and (max-width: 768px) {
  .partners {
    margin-top: 20px;
    gap: 26px;
    width: 100%;
    padding: 0;
  }
}
.partners h1 {
  padding: 0 10px;
}
.partners .partners-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: none;
}
.partners .partners-container .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 24px;
}
@media screen and (max-width: 1200px) {
  .partners .partners-container .swiper-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .partners .partners-container .swiper-wrapper {
    display: flex;
    gap: 0;
  }
}
.partners .partners-container .swiper-wrapper .partners-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  background: #FFF;
}
@media screen and (max-width: 768px) {
  .partners .partners-container .swiper-wrapper .partners-grid-item {
    width: 165px;
    height: 90px;
    flex-shrink: 0;
    margin-right: 24px;
  }
}
.partners .partners-container .swiper-wrapper .partners-grid-item a {
  padding: 10px 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media (hover: hover) {
  .partners .partners-container .swiper-wrapper .partners-grid-item a:hover {
    cursor: pointer;
  }
  .partners .partners-container .swiper-wrapper .partners-grid-item a:hover img {
    scale: 1.1;
  }
}
.partners .partners-container .swiper-wrapper .partners-grid-item a:focus-visible img {
  scale: 1.1;
}
.partners .partners-container .swiper-wrapper .partners-grid-item img {
  width: 100%;
  max-width: 135px;
  max-height: 66px;
  object-fit: scale-down;
  transition: 0.4s ease all;
}

.cookies {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 26px 0;
  background: #D9D9D9;
  display: flex;
  flex-direction: column;
  z-index: 100;
}
.cookies .container {
  width: 100%;
  max-width: 1332px;
  padding-inline: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .cookies .container {
    padding-inline: 10px;
    max-width: 1320px;
  }
}
.cookies .container p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cookies .container p a {
  color: #004F9E;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
}
@media (hover: hover) {
  .cookies .container p a:hover {
    cursor: pointer;
    color: #F78F1E;
  }
}
.cookies .container p a:focus-visible {
  color: #F78F1E;
}
.cookies .container button {
  padding: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  border-radius: 8px;
  transition: ease all 0.4s;
  background: #F78F1E;
}
@media (hover: hover) {
  .cookies .container button:hover {
    cursor: pointer;
    background-color: rgb(241, 126, 18);
  }
}
.cookies .container button:focus-visible {
  background-color: rgb(241, 126, 18);
}

main {
  display: flex;
  flex-direction: column;
  flex-basis: 1;
}

.overflow-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.disabled-link {
  pointer-events: none;
  background-color: #CBC8C8 !important;
  text-decoration: none;
  cursor: default;
}
.disabled-link svg, .disabled-link img {
  opacity: 0.4;
}