@mixin wrapper($max-width : 1300px) {
    $padding : 16px;
    $padding-mobile : 10px;
    width: 100%;
    max-width: calc($max-width + $padding + $padding);
    padding-inline: $padding;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1024px) {
        padding-inline: $padding-mobile;
        max-width: calc($max-width + $padding-mobile + $padding-mobile);
    }

}


// header media (flex-dir col) mmax 1300
@mixin headerMediaLaptop {
    .header .header-content {
        @media screen and (max-width: 1325px) {
            flex-direction: column;
            gap: 24px;
        }

        .nav {
            gap: 32px
        }
    }
}
