footer {

    .container {
        @include wrapper;
        margin-top: 96px;
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        align-items: center;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            margin-top: 60px;
            margin-bottom: 55px;
        }
        @media screen and (max-width: 768px) {
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .info {
            display: flex;
            @media screen and (max-width: 1024px) {
                order: 2;
            }
            @media screen and (max-width: 580px) {
            flex-direction: column;
            align-items: center;
            gap: 18px;
            }

            a{
                transition: 0.4s ease all;
                
                @media (hover : hover){
                   &:hover{
                      cursor:pointer;
                      scale: 1.1;
                   }
                }
                &:focus-visible{
                scale: 1.1;
                }
                >img {
                    display: block;
                    object-fit: scale-down;
                    flex-shrink: 0;
                    max-width: 144px;
                    max-height: 50px;
                }

            }

            .usefull {
                margin-left: 36px;
                display: flex;
                align-items: center;
                gap: 16px;
                @media screen and (max-width: 580px) {
                    flex-direction: column;
                    margin-left: 0;
                    gap: 0;
                }
                a {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: black;

                    @media screen and (max-width: 380px) {
                        display: none;
                    }

                    @media (hover : hover) {
                        &:hover {
                            cursor: pointer;
                            color: $main-pri;
                            text-decoration: underline;
                        }
                    }

                    &:focus-visible {
                        color: $main-pri;
                        text-decoration: underline;
                    }

                    &:last-child{
                        @media screen and (max-width: 580px) {
                            margin-top: -3px;
                        }
                    }
                }
                p{
                    @media screen and (max-width: 580px) {
                        display: none;
                    }
                }

                .mobile{
                    display: none;
                    @media screen and (max-width: 380px) {
                        display: block;
                    }
                    a{
                        display: block;
                        p{
                            display: block;
                            text-decoration: underline;
                            text-align: center;
                        }
                    }
                }

            }
        }
    }

    .usefull-links {
        display: flex;
        height: fit-content;
        gap: 36px;
        align-items: center;

        @media screen and (max-width: 580px) {
            flex-direction: column;
            width: 100%;
            gap: 11px;
        }
        .socials {
            display: flex;
            gap: 24px;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.4s ease all;

                .icon {
                    width: 29px;
                    height: 29px;
                    flex-shrink: 0;
                    transition: 0.4s ease all;
                }

                @media (hover : hover) {
                    &:hover {
                        cursor: pointer;

                        .icon {
                            scale: 1.2;
                        }
                    }
                }

                &:focus-visible {
                    .icon {
                        scale: 1.2;
                    }
                }
            }
        }

        >a {
            padding: 4px 31px 3px 31px;
            background-color: $main;
            border-radius: 5px;
            background: #004F9E;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #fff;
            transition: 0.4s ease all;
            @media screen and (max-width: 580px) {
                padding: 5px;
                width: 100%;
                text-align: center;
            }

            @media (hover : hover) {
                &:hover {
                    cursor: pointer;
                    background-color: $main-pri;
                }
            }

            &:focus-visible {
                background-color: $main-pri;
            }

        }
    }
}