header {
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .container {
        @include wrapper;

        padding-bottom: 28px;
        padding-top: 56px;
        transition: 0.6s ease all;
        display: flex;
        justify-content: space-between;

        &.scrolled {
            padding-bottom: 12px;
            padding-top: 20px;
        }

        @media screen and (max-width: 1260px) {
            flex-direction: column;
            justify-content: initial;

            &.scrolled {
                padding-bottom: 20px;
            }
        }

        @media screen and (max-width: 840px) {
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 12px;
            padding-top: 33px;

            &.scrolled {
                padding-top: 12px;
                padding-bottom: 6px;
            }
        }

        .logos {
            display: flex;
            gap: 42px;
            align-items: center;

            @media screen and (max-width: 1260px) {
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width: 840px) {
                margin: initial;
                gap: 12px;
            }

            a {
                display: flex;
                flex-direction: column;
                width: fit-content;
                height: fit-content;    
                transition: 0.4s ease all;
                will-change: scale;

                @media (hover : hover){
                   &:hover{
                      cursor:pointer;
                      scale: 1.1;
                   }
                }
                &:focus-visible{
                scale: 1.1;
                }

                &:first-child {

                    img {

                        width: 142.718px;
                        height: 60px;
                        flex-shrink: 0;

                        @media screen and (max-width: 840px) {
                            width: 109px;
                            height: 46px;
                            flex-shrink: 0;
                        }
                    }
                }

                &:nth-child(2) {
                    img {
                        width: 170px;
                        height: 60px;
                        flex-shrink: 0;
                        object-fit: cover;

                        @media screen and (max-width: 840px) {
                            height: 49px;
                            max-width: 136px;
                        }
                    }






                }
            }


        }

        .links {
            display: flex;
            gap: 36px;
            align-items: center;
            margin-top: 12px;

            @media screen and (max-width: 1260px) {
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width: 840px) {
                display: none;
            }

            a {
                color: #004F9E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                position: relative;
                transition: 0.6s ease all;

                &.active {
                    &:after {
                        width: calc(100% - 3px);

                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: calc(100% + 6px);
                    left: 1px;
                    height: 5px;
                    background: #F78F1E;
                    width: 0;
                    transition: 0.6s ease all;
                }

                @media (hover : hover) {
                    &:hover {
                        cursor: pointer;
                        color: #F78F1E;

                        &:after {
                            width: calc(100% - 3px);
                        }
                    }
                }

                &:focus-visible {
                    color: #F78F1E;

                    &:after {
                        width: calc(100% - 3px);
                    }
                }
            }
        }

        .mobile-menu-container {
            display: none;

            @media screen and (max-width: 840px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.modal-header-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100%;
    z-index: 1000;
    background-color: rgba(11, 25, 39, 0.8);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    padding: 80px 10px;
    overflow: auto;

    // overscroll-behavior: contain;
    .content {
        position: relative;
        display: flex;
        width: 100%;
        // background-color: $main;
        display: flex;
        flex-direction: column;
        gap: 36px;
        align-items: center;

        .close {
            position: absolute;
            top: -40px;
            right: -10px;
            padding: 10px;
            transition: 0.4s ease all;

            @media (hover : hover) {
                &:hover {
                    cursor: pointer;
                    scale: 1.1;
                }
            }

            &:focus-visible {
                scale: 1.1;
            }

            .line {
                width: 34px;
                height: 3px;
                border-radius: 30px;
                background: #F78F1E;
                flex-shrink: 0;

                &.line1 {
                    rotate: 45deg;
                    transform-origin: center center;
                }

                &.line2 {
                    position: relative;
                    top: -3px;
                    transform: translateY(1px);
                    rotate: -45deg;
                    transform-origin: center center;
                }
            }
        }

        a {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: relative;
            transition: 0.6s ease all;



            &.active {
                &:after {
                    width: calc(100% - 3px);

                }
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(100% + 6px);
                left: 1px;
                height: 5px;
                background: #F78F1E;
                width: 0;
                transition: 0.6s ease all;
            }

            @media (hover : hover) {
                &:hover {
                    cursor: pointer;
                    color: #F78F1E;

                    &:after {
                        width: calc(100% - 3px);
                    }
                }
            }

            &:focus-visible {
                color: #F78F1E;

                &:after {
                    width: calc(100% - 3px);
                }
            }
        }
    }
}