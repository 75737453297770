:root {
}

//colors
$white : #FFF;
$main : #004F9E;
$main-pri: #0d6bca;

//fonts
$font-family: "Noto Sans", serif;

