.index-main-banner {
    margin-inline: auto;
    max-width: 2200px;
    width: 100%;
    display: flex;
    overflow: hidden;
    margin-top: 28px;

    @media screen and (max-width: 768px) {
        margin-top: 12px;
    }

    .left-container {
        max-width: 820px;
        width: 37.63%;
        flex-shrink: 0;
    }

    .container {
        width: 100%;
        min-width: 828px;
        padding: 0 93px 0 96px;
        min-height: 624px;

        display: flex;
        flex-direction: column;
        gap: 44px;

        @media screen and (max-width: 1240px) {
            min-width: 0;
            padding: 0 40px;
        }

        @media screen and (max-width: 1024px) {
            padding: 0 10px;
            gap: 25px;
            min-height: 0;
        }

        h1 {
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .right-container {
        width: 24.72%;
        max-width: 544px;
        flex-shrink: 0;
    }

    @media screen and (max-width: 1400px) {
        .left-container {
            width: 15%;
        }

        .right-container {}
    }

    @media screen and (max-width: 1024px) {
        .left-container {
            display: none;
        }

        .right-container {
            display: none;
        }

        .container {
            min-width: 0;
        }
    }

    .image-container {
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            border-radius: 8px;
            display: block;
            object-fit: cover;
        }
    }

    .left-container {
        img {

            &:nth-child(1) {
                max-width: 260px;
                height: 240px;
                top: 0;
                right: 0;
            }

            &:nth-child(2) {
                right: calc(260px + 23px);
                top: 0;
                width: 260px;
                height: 360px;
            }

            &:nth-child(3) {
                right: calc(260px + 23px + 260px + 23px);
                top: 0;
                width: 252px;
                height: 240px;
            }

            &:nth-child(4) {
                right: 0;
                top: calc(240px + 23px);
                width: 260px;
                height: 360px;
            }

            &:nth-child(5) {
                right: calc(23px + 260px);
                top: calc(360px + 23px);
                width: 260px;
                height: 240px;
            }

            &:nth-child(6) {
                right: calc(23px + 23px + 260px + 260px);
                top: calc(240px + 23px);
                width: 252px;
                height: 360px;
            }
        }
    }

    .right-container {
        img {
            &:nth-child(1) {
                left: 0;
                top: 0;
                width: 260px;
                height: 360px;
            }

            &:nth-child(2) {
                left: calc(23px + 260px);
                top: 0;
                width: 260px;
                height: 240px;
            }

            &:nth-child(3) {
                left: 0;
                top: calc(23px + 360px);
                width: 260px;
                height: 240px;
            }

            &:nth-child(4) {
                left: calc(23px + 260px);
                top: calc(23px + 240px);
                width: 260px;
                height: 360px;
            }
        }
    }

}

.contest {
    margin-top: 158px;
    max-width: 1920px;
    width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 1921px) {
        border-radius: 8px;
    }

    background: url('../../assets/backgrounds/contest.png');
    background-size: cover;
    background-repeat: no-repeat;

    .absolute-bg {
        position: absolute;
        top: -600px;
        left: 0;
        width: 100%;
        height: calc(100% + 700px);
        background: url('../../assets/backgrounds/bg1.svg');
        background-repeat: repeat;
        z-index: 0;
    }



    @media screen and (max-width: 1024px) {
        margin-top: 80px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 18px;
    }


    .content {
        @include wrapper;
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding-top: 78px;
        padding-bottom: 174px;
        gap: 24px;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            padding-top: 50px;
            padding-bottom: 100px;
        }

        @media screen and (max-width: 768px) {
            padding-top: 30px;
            padding-bottom: 34px;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        .text {
            grid-column: 1 / 6;

            display: flex;
            flex-direction: column;
            gap: 21px;

            h1 {
                color: white;
                max-width: 440px;
                font-weight: 700;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: white;
                max-width: 658px;
            }
        }

        .video-block {
            grid-column: 6 / 10;
            margin-top: 25px;
            margin-bottom: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            iframe {
                width: 100%;
                aspect-ratio: 560 / 315;
                border-radius: 8px;
            }
        }

    }

}

.terms-of-participation {
    @include wrapper;
    margin-top: 106px;
    margin-bottom: 123px;
    display: flex;
    flex-direction: column;
    gap: 46px;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
        margin-bottom: 36px;
        gap: 32px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 24px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 24px;
        }


        .terms-grid-item {
            display: flex;
            gap: 24px;
            align-items: center;

            @media screen and (max-width: 768px) {
                align-items: start;
                gap: 11px;

            }

            .border {
                width: 72px;
                height: 72px;
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #F78F1E;

                @media screen and (max-width: 768px) {
                    width: 44px;
                    height: 44px;
                    flex-shrink: 0;
                }

                .icon {
                    width: 40px;
                    height: 40px;

                    @media screen and (max-width: 768px) {
                        width: 24px;
                        height: 24px;
                    }

                }
            }

            .text-block {
                display: flex;
                flex-direction: column;
                gap: 0px;
                width: 100%;


                .desk{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                .mobile{
                    display: none;
                    @media screen and (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                    }
                }

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                    }
                }

                &.small{
                    @media screen and (max-width: 768px) {
                        p{

                            font-size: 14px;
                        }
                    }
                }

                ul {
                    li {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        @media screen and (max-width:  768px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            &:nth-child(2) {
                p {
                    max-width: 351px;

                    @media screen and (max-width: 768px) {
                        max-width: none;
                    }
                }
            }

            &:nth-child(3) {
                p {
                    max-width: 250px;

                    @media screen and (max-width: 768px) {
                        max-width: none;
                    }
                }
            }

            &:nth-child(4) {
                p {
                    max-width: 281px;

                    @media screen and (max-width: 768px) {
                        max-width: none;
                    }
                }
            }

            &:nth-child(5) {
                p {

                    @media screen and (max-width: 768px) {
                        max-width: none;
                    }
                }
            }

            &:nth-child(6) {
                p {

                    @media screen and (max-width: 768px) {
                        max-width: none;
                    }
                }
            }



        }


    }
}


.stages {
    @media screen and (min-width: 1921px) {
        border-radius: 8px;
    }

    position: relative;
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    background: url('../../assets/backgrounds/main.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-inline: auto;

    .absolut-bg {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('../../assets/backgrounds/bg1.svg');
        background-repeat: repeat;
        z-index: 0;
    }

    .content {
        @include wrapper;
        display: flex;
        flex-direction: column;
        margin-top: 98px;
        margin-bottom: 120px;
        position: relative;

        @media screen and (max-width: 1024px) {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        @media screen and (max-width: 768px) {
            margin-top: 25px;
            margin-bottom: 51px;
        }

        h1 {
            color: white;
        }


        .stages-container {
            margin-top: 45px;
            display: flex;
            flex-direction: column;
            gap: 64px;

            @media screen and (max-width: 768px) {
                gap: 27px;
            }




            .stages-item {
                display: grid;
                grid-template-columns: repeat(14, 1fr);
                gap: 24px;
                position: relative;

                @media screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    gap: 38px;
                    padding-left: 30px;
                    padding-right: 11px;
                }

                .times {
                    grid-column: 1 / 5;
                    display: flex;
                    flex-direction: column;
                    align-items: end;

                    @media screen and (max-width: 1024px) {
                        // grid-column: 1 /6;
                    }

                    @media screen and (max-width: 768px) {
                        align-items: start;
                    }

                    h2 {
                        color: white;
                        text-align: right;
                        width: fit-content;

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }
                }

                &:before {
                    @media screen and (max-width: 768px) {
                        position: absolute;
                        left: -2px;
                        content: '';
                        top: 5px;
                        width: 24px;
                        height: 24px;
                        background-color: #fff;
                        border-radius: 100%;
                    }
                }

                &:after {

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        left: 9px;
                        content: '';
                        top: 5px;
                        width: 2px;
                        height: calc(100% + 27px + 5px);
                        background-color: #fff;
                    }
                }


                &:last-of-type {}

                &:last-child {
                    &:after {
                        display: none;
                    }

                    .line {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .info-block-container {
                grid-column: 5/ 15;

                display: grid;
                grid-template-columns: repeat(10, 1fr);
                gap: 24px;

                @media screen and (max-width: 1024px) {
                    // grid-column: 6 /15;
                    grid-template-columns: repeat(9, 1fr);
                }

                @media screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;

                }

                .line {
                    grid-column: 1 /3;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media screen and (max-width: 1024px) {
                        grid-column: 1/2;
                    }

                    @media screen and (max-width: 768px) {
                        display: none
                    }


                    &:before {
                        position: absolute;
                        top: -12px;
                        background-color: #fff;
                        width: 25px;
                        height: 25px;
                        border-radius: 100%;
                        content: '';

                        @media screen and (max-width: 1024px) {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        height: calc(100% + 64px);
                        width: 2px;
                        background: #FFF;
                    }
                }

                .block {}


            }
        }


    }




}


@mixin btn-link {

    >a,
    .part>a {
        margin-top: 31px;
        padding: 20px;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 8px;
        background-color: $main;
        width: fit-content;
        transition: 0.4s ease all;

        display: flex;
        flex-direction: column;
        align-items: center;

        @media (hover : hover) {
            &:hover {
                cursor: pointer;
                background-color: $main-pri;
            }
        }

        &:focus-visible {
            background-color: $main-pri;
        }

        @media screen and (max-width: 768px) {
            margin-top: 24px;
            padding: 14px 10px;
            text-align: center;
            font-size: 12px;
            // margin-inline: auto;
        }
    }
}

.stage-block {
    grid-column: 3 / 10;
    padding: 24px 0 31px 0;
    background-color: #fff;
    border-radius: 8px;
    position: relative;

    @media screen and (max-width: 1300px) {
        grid-column: 3 / 11;
    }

    @media screen and (max-width: 1024px) {
        grid-column: 2/10;
    }

    @media screen and (max-width: 768px) {
        padding: 20px 0 24px 0;
        display: none;
    }

    &:after {
        content: '';
        position: absolute;
        left: -24px;
        top: 0;
        width: 40px;
        height: 22px;
        background: url('../../assets/icons/polygon-horiz.svg');
        background-repeat: no-repeat;

        @media screen and (max-width: 768px) {
            top: -14px;
            left: 0;
            background: url('../../assets/icons/polygon-vertical.svg');
            background-repeat: no-repeat;
            width: 19.5px;
            height: 40px;
        }
    }

    &.mobile{
        display: none;
        // @media screen and (max-width: 768px) {
        //     display: flex;
        //     flex-direction: column;
        // }
    }


    .part {
        display: flex;
        flex-direction: column;
        padding: 0 45px 0 37px;

        @media screen and (max-width: 768px) {
            padding: 0 7px 0 20px;
        }

        p {
            margin-top: 19px;

            @media screen and (max-width: 768px) {
                margin-top: 13px;
            }
        }

        h3 {
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }

            &.smaller {
                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
            }
        }


    }

    .horizontal-line {
        width: 100%;
        height: 1px;
        background: #CECECE;
        margin-top: 36px;
        margin-bottom: 24px;

        @media screen and (max-width: 768px) {
            margin-top: 25px;
        }
    }

    &-1,
    &-4 {
        display: flex;
        flex-direction: column;
        @include btn-link;
    }

    &-2 {
        display: flex;
        flex-direction: column;

        .links {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 31px;

            @media screen and (max-width: 840px) {
                display: flex;
                flex-direction: column;
                align-items: start;
            }

            @media screen and (max-width: 768px) {
                gap: 12px;
                margin-top: 19px;
            }
        }

        .part {

            @include btn-link;
        }
    }

    &-3 {
        display: flex;
        flex-direction: column;

        .part {

            @include btn-link;

            .links {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 31px;
                margin-bottom: 32px;

                p {
                    text-align: center;
                }

                @media screen and (max-width: 840px) {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                }

                @media screen and (max-width: 768px) {
                    gap: 12px;
                    margin-top: 19px;
                    margin-bottom: 19px;

                    a {
                        p {
                            width: fit-content;

                        }
                    }
                }

            }

            .animation-container {
                display: flex;
                flex-direction: column;

                .heading {
                    display: flex;
                    gap: 24px;
                    align-items: center;

                    @media screen and (max-width: 768px) {
                        flex-direction: column;

                    }



                    @media (hover : hover) {
                        &:hover {
                            cursor: pointer;

                            .box {

                                h6 {
                                    color: $main-pri;
                                }
                            }
                        }
                    }

                    &:focus-visible {}

                    &.active {
                        svg {
                            rotate: 180deg;
                            color: #F78F1E;
                        }
                    }

                    .box {
                        h6 {
                            color: #004F9E;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 800;
                            text-decoration: underline;
                            transition: 0.4s ease all;
                        }
                    }

                    svg {
                        flex-shrink: 0;
                        transition: 0.6s ease all;
                        color: #d9d9d9;
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;

                    .body-content {
                        margin-top: 22px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 24px;

                        @media screen and (max-width: 768px) {
                            grid-template-columns: 1fr;
                            gap: 0;
                        }

                        .item {
                            p {
                                margin: 0;
                                line-height: 200%;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
    &-1, &-2, &-3,&-4,&-5{
        .links {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 31px;

            @media screen and (max-width: 840px) {
                display: flex;
                flex-direction: column;
                align-items: start;
            }

            @media screen and (max-width: 768px) {
                gap: 12px;
                margin-top: 19px;
            }
        }
    }


}
.stage-block{
    @media screen and (max-width: 768px) {
        display: none;
    }

    &.mobile{
        display: none;
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
}


.stage-link-with-icon {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 20px 20px 20px 18px;
    background-color: $main;
    transition: 0.4s ease all;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
        padding: 14px 10px;
        width: fit-content;
        align-items: center;
        justify-content: center;
    }

    @media (hover : hover) {
        &:hover {
            cursor: pointer;
            background-color: $main-pri;
        }
    }

    &:focus-visible {
        background-color: $main-pri;
    }

    p {
        margin-top: 0px !important;
        font-size: 20px;
        font-style: normal;
        color: white;
        font-weight: 600;
        line-height: normal;

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    img {
        width: 25px;
        height: 25px;
        flex-shrink: 0;

        @media screen and (max-width: 768px) {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
        }
    }
}


.winners {
    @include wrapper;
    margin-top: 100px;

    @media screen and (max-width: 1024px) {
        margin-top: 60px;
    }

    @media screen and (max-width: 76px) {
        margin-top: 30px;
    }

    &__container {
        margin-top: 36px;
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        gap: 24px;

        @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(10, 1fr);
        }

        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 14px;
        }

        .winners-item {
            grid-column: span 4;
            padding: 24px 27px 23px 22px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;
            color: white;
            background-color: #D9D9D9;
            border-radius: 8px;

            @media screen and (max-width: 1024px) {
                grid-column: initial;
            }

            @media screen and (max-width: 768px) {
                padding: 23px 11px 17px 19px;
            }

            img {
                position: absolute;
                top: 0;
                border-radius: 8px;
                width: 100%;
                height: 100%;
                left: 0;
                z-index: 0;
                object-fit: cover;
            }

            .heading {
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;
                z-index: 2;

                .count {
                    width: 63px;
                    height: 72px;
                    flex-shrink: 0;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: 768px) {
                        width: 53px;
                        height: 72px;
                        flex-shrink: 0;
                    }

                    p {
                        color: white;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                        margin-top: 0;

                        @media screen and (max-width: 768px) {
                            font-size: 60px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                        }
                    }

                    &-1 {
                        background: linear-gradient(24deg, rgba(203, 176, 22, 0.35) 3.48%, rgba(255, 174, 0, 0.70) 38.96%, rgba(203, 176, 22, 0.70) 92.19%), #D9D9D9;
                    }

                    &-2 {
                        background: linear-gradient(201deg, rgba(0, 0, 0, 0.00) 11.66%, rgba(0, 0, 0, 0.40) 97.37%), #D9D9D9;

                    }

                    &-3 {
                        background: linear-gradient(0deg, rgba(135, 91, 21, 0.70) -9.65%, rgba(124, 69, 6, 0.70) 52.72%, rgba(135, 91, 21, 0.70) 104.79%), #D9D9D9;

                    }

                }

                h3 {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
            }

            p {
                position: relative;
                z-index: 2;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }


            &.big {
                grid-column: span 6;
                position: relative;
                z-index: 2;

                @media screen and (max-width: 1024px) {
                    grid-column: initial;
                }
            }

            &:nth-child(4) {
                @media screen and (max-width: 1200px) {
                    grid-column: span 6;
                }

                @media screen and (max-width: 1024px) {
                    grid-column: initial;
                }
            }

            &:nth-child(5) {
                @media screen and (max-width: 1200px) {
                    grid-column: span 6;
                }

                @media screen and (max-width: 1024px) {
                    grid-column: initial;
                }
            }
        }
    }
}

.history {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;
    margin-top: 111px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        margin-top: 60px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 36px;
    }

    .container {
        @include wrapper;
        position: relative;
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        gap: 24px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        .content {
            grid-column: span 9;
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding-bottom: 60px;

            @media screen and (max-width: 768px) {
                padding-bottom: 0;
            }

            .text-block {
                display: flex;
                flex-direction: column;
                gap: 34px;
            }


            h2 {
                margin-top: 45px;
                max-width: 638px;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    margin-top: 22px;
                }
            }

            h2+p {
                max-width: 638px;
            }

            h3 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                @media screen and (max-width: 768px) {
                    margin-top: 13px;
                    font-size: 16px;
                }
            }

        }

        .image-block {
            grid-column: span 5;
            margin-top: 19px;
            position: relative;

            @media screen and (max-width: 768px) {
                display: none;
            }

            img {

                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px;
                height: 100%;
                width: 760px;
                object-fit: cover;
            }
        }
    }
}

.partners {
    @include wrapper;
    margin-top: 125px;
    display: flex;
    flex-direction: column;
    gap: 64px;



    @media screen and (max-width: 1024px) {
        margin-top: 70px;
        gap: 48px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 20px;
        gap: 26px;
        width: 100%;
        padding: 0;
    }

    h1 {
        padding: 0 10px;
    }

    .partners-container {


        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: none;

        @media screen and (min-width: 769px) {}

        .swiper-wrapper {

            display: grid;
            grid-template-columns: repeat(7, 1fr);

            @media screen and (max-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media screen and (max-width: 768px) {
                display: flex;
                gap: 0;


            }

            gap: 24px;

            .partners-grid-item {
                
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #E1E1E1;
                background: #FFF;

                @media screen and (max-width: 768px) {
                    width: 165px;
                    height: 90px;
                    flex-shrink: 0;
                    margin-right: 24px;
                }

                a{
                    padding: 10px 12px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @media (hover : hover){
                       &:hover{
                          cursor:pointer;
                          img{
                            scale: 1.1;
                          }
                       }
                    }
                    &:focus-visible{
                    img{
                        scale: 1.1;
                    }
                    }

                }

                img {
                    width: 100%;
                    max-width: 135px;
                    max-height: 66px;
                    object-fit: scale-down;
                    transition: 0.4s ease all;
                }
            }
        }

    }
}

.cookies {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 26px 0;
    background: #D9D9D9;
    display: flex;
    flex-direction: column;
    z-index: 100;

    .container {
        @include wrapper;
        display: flex;
        gap: 24px;
        justify-content: space-between;

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            a {
                color: #004F9E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration: underline;

                @media (hover : hover) {
                    &:hover {
                        cursor: pointer;
                        color: #F78F1E;
                    }
                }

                &:focus-visible {
                    color: #F78F1E;
                }
            }
        }

        button {
            padding: 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #fff;
            border-radius: 8px;
            transition: ease all 0.4s;
            background: #F78F1E;

            @media (hover : hover) {
                &:hover {
                    cursor: pointer;
                    background-color: rgb(241, 126, 18);
                }
            }

            &:focus-visible {
                background-color: rgb(241, 126, 18);
            }
        }
    }
}