/* utils */
@import "./utils/reset";
@import "./utils/vars";
@import "./utils/base";
@import "./utils/utils";
@import "./utils/mixins";
@import "./utils/sticky-footer";

/* Components */
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_index-page.scss";
@import "components/_main.scss";


.disabled-link{
    pointer-events: none;
    background-color: #CBC8C8 !important; 
    text-decoration: none;
    cursor: default;

    svg, img{
        opacity: 0.4;
    }

}

