html, body {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    position: relative;
}

header{
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
}


footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}
