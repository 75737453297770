html {
    scroll-behavior: smooth;

}

body {
    font-family: $font-family;

    // background-image: url('../../assets/backgrounds/main.png');
    // background-repeat: no-repeat;
    // background-size: cover;


}
p {
    font-size: 16px;

    span {
        background-color: #F78F1E;
        // color: white;

    }
    a{
        color: #3180D0;
        text-decoration: underline !important;
        font-weight: 400;
        transition: 0.4s ease all;

        @media (hover : hover){
           &:hover{
              cursor:pointer;
              color: #F78F1E;
           }
        }
        &:focus-visible{
        color: #F78F1E;
        }

    }
}


h1 {
    font-size: 64px;
    font-weight: 700;

    @media screen and (max-width: 1024px) {
        font-size: 32px;
    }
}

h2 {

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


    @media screen and (max-width: 1024px) {
        font-size: 24px;
    }
    span {
        background-color: #F78F1E;
        color: white;
        font-size: 36px;
        @media screen and (max-width: 1024px) {
            font-size: 26px;
        }
    }
}

h3 {}

h4 {}

h5 {}


.wrapper-color {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
}