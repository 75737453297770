main{
    display: flex;
    flex-direction: column;
    flex-basis: 1;
}


.overflow-container{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

